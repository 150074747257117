import React, {useEffect, useState} from "react";
import {Box, XCol, XPush} from "../xui/Box";
import {themeDark} from "../../page-styles/landingpage.css";
import Icon from "../Icon";
import {videoPlayerStyles as styles} from "./VideoPlayer.css";
import cx from "../xui/cx";
import {ThemedButton} from "../xui/ThemedButton";
import YoutubeVideo from "./YoutubeVideo";
import {uiStyles} from "../xui/xui.css";

const CloseButton = ({show, onClick}) => (
  <Box absolute top="0" left="100%" pl={3} fadeEffect={show ? "toTarget" : "fromTransparent"}>
    <ThemedButton size="smSquare" onClick={onClick}>
      <Icon.Close />
    </ThemedButton>
  </Box>
);

const Cover = ({youtubeId, shown, title, onClick}) => {
  const imgUrl = `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
  return (
    <XCol
      absolute
      inset={0}
      zIndex={1}
      pointerEvents={!shown && "none"}
      fadeEffect={shown ? "toTarget" : "fromTransparent"}
      className={styles.coverOuter}
    >
      <Box absolute inset={0} style={{backgroundImage: `url(${imgUrl})`}} />
      <XCol
        className={cx(styles.cover.base, !shown && styles.cover.hidden)}
        relative
        fillParent
        style={{background: "rgba(23, 19, 25, 0.75)"}}
        px={5}
        align="center"
      >
        <XPush />
        <Box as="h4" size="xxxl" align="center">
          {title}
        </Box>
        <div style={{flexGrow: "3"}} />
      </XCol>
      <XCol absolute inset={0} pointerEvents="none" align="center" justify="center">
        <Box pointerEvents="auto">
          <ThemedButton size="lgSquare" onClick={onClick} kind="primary">
            <Icon.Play className={uiStyles.size.xxl} />
          </ThemedButton>
        </Box>
      </XCol>
    </XCol>
  );
};

export const VideoPlayer = ({title, youtubeId, children}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [shownOnce, setShownOnce] = useState(false);
  const handleShow = () => {
    setShowVideo(true);
    setShownOnce(true);
  };
  useEffect(() => {
    if (!showVideo) {
      let timeoutId = setTimeout(() => {
        setShownOnce(false);
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [showVideo]);
  return (
    <XCol sp={9} align="center" relative zIndex={1} pt={9} onClick={() => setShowVideo(false)}>
      <XCol
        sp={8}
        className={cx(styles.contentArea.base, showVideo && styles.contentArea.hidden)}
        maxWidth="lg"
      >
        {children}
      </XCol>
      <Box
        relative
        width="100%"
        maxWidth="xxl"
        className={cx(styles.box.base, themeDark, showVideo && styles.box.shown)}
        onClick={e => {
          e.stopPropagation();
          handleShow();
        }}
      >
        <CloseButton
          show={showVideo}
          onClick={e => {
            e.stopPropagation();
            setShowVideo(false);
          }}
        />
        <Box
          bg="bgBack"
          height="100%"
          className={styles.boxContent.base}
          relative
          overflow="hidden"
          elevation="lg"
        >
          <Cover shown={!showVideo} youtubeId={youtubeId} title={title} />
          {shownOnce && <YoutubeVideo videoId={youtubeId} autoplay />}
        </Box>
      </Box>
    </XCol>
  );
};
