import React, {useEffect, useRef, useState} from "react";
import DefaultLayout from "../components/DefaultLayout";
import {ProductHero} from "../components/bricks/Hero";
import {useAppInfo} from "../features/AppInfo";
import {Box, XCol, XRow} from "../components/xui/Box";
import {themeDark} from "../page-styles/landingpage.css";
import ThemeBox from "../components/bricks/ThemeBox";
import {ThemedButton} from "../components/xui/ThemedButton";
import {useAuthFlow} from "../features/auth/oauth-flow";
import socialMediaImg from "../imgs/social-media-steamy.png";
import ThemedSingleBoxFeature from "../components/bricks/ThemedSingleBoxFeature";
import {InnerCardFall} from "../components/CardFall";
import {VideoPlayer} from "../components/bricks/VideoPlayer";
import BoxedFeatureImage from "../components/bricks/BoxedFeatureImage";
import {steamBotStyles as styles} from "../page-styles/steam-bot.css";
import cx from "../components/xui/cx";
import {withFadeTransition} from "../components/xui/xui.css";

const steamyUrl = process.env.GATSBY_STEAMY_HOST;

const CtaButton = ({label, hint, isLoggedIn, ...props}) => (
  <XCol align="center" sp={2}>
    {isLoggedIn ? (
      <ThemedButton kind="primary" href={steamyUrl}>
        Open Steamy
      </ThemedButton>
    ) : (
      <>
        <ThemedButton kind="primary" {...props}>
          {label}
        </ThemedButton>
        <Box color="secondary" size="md">
          {hint}
        </Box>
      </>
    )}
  </XCol>
);

const useAnimState = ({onBump, maxBumpCount, onReset}) => {
  const [state, setState] = useState("start");
  const refs = useRef({onBump, maxBumpCount, state, onReset});
  useEffect(() => {
    refs.current = {onBump, maxBumpCount, state, onReset};
  });
  useEffect(() => {
    let timeoutId = null;
    let bumpCount = 0;
    const onStart = () => {
      setState("scrolled");
      const next = () => {
        clearTimeout(timeoutId);
        if (refs.current.state !== "bump") {
          if (bumpCount >= refs.current.maxBumpCount - 1) {
            setState("done");
            timeoutId = setTimeout(() => {
              refs.current.onReset();
              bumpCount = 0;
              setState("start");
              timeoutId = setTimeout(onStart, 2000);
            }, 3000);
          } else {
            bumpCount += 1;
            refs.current.onBump();
            timeoutId = setTimeout(next, 300);
            setState("bump");
          }
        } else {
          timeoutId = setTimeout(next, 1500);
          setState("scrolled");
        }
      };
      timeoutId = setTimeout(next, 1000);
    };
    timeoutId = setTimeout(onStart, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return state;
};

const bumpImgs = [
  "steamy/rating-D.png",
  "steamy/rating-E.png",
  "steamy/rating-D.png",
  "steamy/rating-E.png",
];

const RightArea = ({state, bumpCount}) => (
  <Box
    relative
    className={cx(
      styles.rightBox,
      styles.animBox.base,
      styles.animBox.right,
      styles.animBox.state[state]
    )}
    width="100%"
    overflow="hidden"
    style={{background: "#313338"}}
  >
    <XCol
      absolute
      top={0}
      left={0}
      right={0}
      style={{
        height: `${bumpImgs.length * 100}%`,
        transform: `translate3D(0,-${(bumpCount / bumpImgs.length) * 100}%,0)`,
      }}
      className={withFadeTransition}
    >
      {bumpImgs.map((img, idx) => (
        <XCol key={idx} fillParent relative>
          <XCol absolute inset={0} pa={4} align="center" justify="center">
            <BoxedFeatureImage img={img} elevation={null} />
          </XCol>
        </XCol>
      ))}
    </XCol>
  </Box>
);

const BumpAnimation = ({title, subline, button}) => {
  const [bumpCount, setBumpCount] = useState(0);
  const state = useAnimState({
    maxBumpCount: bumpImgs.length,
    onBump: () => setBumpCount(p => p + 1),
    onReset: () => setBumpCount(0),
  });
  return (
    <>
      <XCol sp={6} className={cx(styles.contentArea.base, styles.contentArea.state[state])}>
        <Box as="h1" weight="bold" size="xxxl" maxWidth="md" color="purple100" textAlign="center">
          {title}
        </Box>
        <Box as="h2" maxWidth="lg" weight="normal" size="xl" color="purple300" textAlign="center">
          {subline}
        </Box>
        {button}
      </XCol>
      <XRow width="100%">
        <BoxedFeatureImage
          img="steamy/steam-reviews.png"
          type="full"
          className={cx(styles.animBox.base, styles.animBox.left, styles.animBox.state[state])}
        />
        <RightArea state={state} bumpCount={bumpCount} />
      </XRow>
      <div className={cx(styles.bumpHighlight.base, styles.bumpHighlight.state[state])} />
    </>
  );
};

const SteamBot = ({location}) => {
  const buttonProps = useAuthFlow({
    provider: "discord",
    intent: "signup",
    onDone: () => {
      window.location.href = steamyUrl;
    },
  });

  const appInfo = useAppInfo();
  const isLoggedIn = !!appInfo.user;

  return (
    <DefaultLayout
      title="Steamy - Steam Discord Bot for Game Developers"
      description="The Discord bot for Steam Game Developers"
      img={socialMediaImg}
      location={location}
      footer={null}
    >
      <ProductHero logo="Steamy">
        <BumpAnimation
          button={
            <CtaButton label="Add Steamy to your Server" isLoggedIn={isLoggedIn} {...buttonProps} />
          }
          title="The Discord bot for Steam Game Developers"
          subline="Steamy is free for all teams."
        />
      </ProductHero>
      <ThemeBox theme={themeDark}>
        <ThemedSingleBoxFeature
          title="Fresh Steam Reviews!"
          img="steamy/review-on-discord.png"
          imgRight
        >
          Post Steam reviews to your Discord Server automatically and stay on top of what players
          are saying about your game(s)!
        </ThemedSingleBoxFeature>
        <ThemedSingleBoxFeature title="Engage your Community!" img="steamy/admin-form.png">
          Post reviews to a private channels and celebrate with your team. Or post reviews to public
          channels and inspire your community to leave more reviews!
        </ThemedSingleBoxFeature>
        <ThemedSingleBoxFeature title="Track your Steam rating!" img="steamy/summary.png" imgRight>
          See how your Steam rating changes over time with weekly digests.
        </ThemedSingleBoxFeature>
        <ThemedSingleBoxFeature title="Translations" img="steamy/translations.png">
          Automatically translates reviews into English. Completely free because we{" "}
          <span role="img" aria-label="heart">
            💜
          </span>{" "}
          Game Developers
        </ThemedSingleBoxFeature>
      </ThemeBox>
      <ThemeBox
        theme={themeDark}
        bg="bgAccent"
        py={null}
        outerChild={<InnerCardFall cardPer100px={3} fadeIn />}
      >
        <VideoPlayer youtubeId="D1pPWxNYufg" title="Quick tour of Steamy">
          <Box as="h2" textAlign="center" size="xxxl" color="primary">
            Steamy is already used on over 500 Game Dev Discord Servers
          </Box>
          <CtaButton label="Add Steamy to your Server" isLoggedIn={isLoggedIn} {...buttonProps} />
        </VideoPlayer>
      </ThemeBox>
    </DefaultLayout>
  );
};

export default SteamBot;
