import React from "react";
import Img from "gatsby-image";
import {useStaticQuery, graphql, Link} from "gatsby";
import {Box} from "../xui/Box";
import cx from "../xui/cx";
import {imageStyles} from "./themed-bricks.css";

const featureImageQuery = graphql`
  {
    allFile(
      filter: {
        relativeDirectory: {glob: "{feature-images/*,feature-images,hero-images}"}
        extension: {ne: "svg"}
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 960, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

const BoxedFeatureImage = ({img, alt, styles, to, type, ...rest}) => {
  const {allFile} = useStaticQuery(featureImageQuery);
  const imgData = allFile.nodes.find(n => n.relativePath.endsWith(img));

  if (!imgData) return null;
  const {fluid} = imgData.childImageSharp;

  const width = Math.min(100, fluid.aspectRatio * 100 * 0.66);

  const comp = (
    <Box
      as={Img}
      fluid={fluid}
      style={{
        width: width !== 100 ? `${width}%` : undefined,
        ...styles,
      }}
      alt={alt || ""}
      // rounded="md"
      elevation="lg"
      width="100%"
      relative
      zIndex={1}
      {...rest}
      className={cx(imageStyles.boxed[type], rest.className)}
    />
  );

  return to ? (
    <Link to={to} css={{display: "block", width: "100%"}}>
      {comp}
    </Link>
  ) : (
    comp
  );
};

export default BoxedFeatureImage;
