import React, {useEffect, useState} from "react";
import BoxedFeatureImage from "./BoxedFeatureImage";
import {Box, XCol, XRow} from "../xui/Box";
import {createObserver} from "../ColorScroller";

const Image = ({img, imgAlt, isRight}) => {
  return (
    <XRow
      fillParent
      justify="center"
      width="60%"
      md_width="100%"
      pt={isRight && 7}
      pb={!isRight && 7}
      md_pt={0}
      md_pb={0}
    >
      <BoxedFeatureImage img={img} alt={imgAlt} type={isRight ? "right" : "left"} />
    </XRow>
  );
};

const ThemedSingleBoxFeature = ({title, img, imgAlt, imgRight, children}) => {
  const [node, setNode] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (node) {
      return createObserver({
        node,
        onIntersect: () => setVisible(true),
        threshold: 0.5,
      });
    }
  }, [node]);
  return (
    <XRow
      pl={!imgRight && 9}
      pr={imgRight && 9}
      md_pl={!imgRight && 0}
      md_pr={imgRight && 0}
      ref={setNode}
    >
      <XRow
        sp={8}
        md_sp={6}
        align="center"
        md_col
        fillParent
        bg={imgRight ? "bgAccent" : "bgAccentSecondary"}
        rounded="sm"
        pl={imgRight && 7}
        pr={!imgRight && 7}
        md_pl={imgRight && 0}
        md_pr={!imgRight && 0}
        fadeEffect={visible ? "toTarget" : imgRight ? "fromLeft" : "fromRight"}
        overflow="hidden"
      >
        {!imgRight && <Image img={img} imgAlt={imgAlt} />}
        <XCol
          fillParent
          align="start"
          width="40%"
          md_width="100%"
          md_order={1}
          md_aligh="center"
          md_pl={6}
          md_pr={6}
          md_pb={6}
        >
          <XCol sp={6} align="start" width="100%">
            <XCol sp={4} align="start" maxWidth="md" md_maxWidth="100%" width="100%">
              {title && (
                <Box as="h3" bold size="xxl" color="secondary">
                  {title}
                </Box>
              )}
              {title && <Box as="hr" borderWidth="0" bg="themeAccent" height="3px" width="2.5" />}
              <Box color="body" size="lg" lineHeight="wide" width="100%">
                {children}
              </Box>
            </XCol>
          </XCol>
        </XCol>
        {imgRight && <Image img={img} imgAlt={imgAlt} isRight />}
      </XRow>
    </XRow>
  );
};
export default ThemedSingleBoxFeature;
